<template>
    <div>
        <div class="hot-route-box clearfix" ref="cont">
            <!-- for循环开始 -->
            <div
                v-for="(item, index) in hotRouteList"
                @click="goDownloadPage"
                class="hot-route-item"
                ref="box"
                @mouseenter="showQRcode"
                @mouseleave="hideQRcode"
                :key="index"
            >
                <div class="qr-code">
                    <img
                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626251728619.png"
                    />
                </div>
                <div class="hot-route-img">
                    <img
                        class="hot-route-img-photo"
                        :src="item.imgUrl"
                        alt="image"
                    />
                    <img
                        v-if="item.isVideo"
                        class="hot-route-img-play"
                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626682321533.png"
                    />
                    <div
                        v-if="item.productType == 14"
                        class="hot-route-img-mask"
                    >
                        <span>品牌名宿</span>
                    </div>
                    <div v-else-if="item.productType == 2"></div>
                    <div v-else class="hot-route-img-mask">
                        <span>官方结伴</span>
                    </div>
                </div>
                <!-- 品牌名宿 -->
                <div v-if="item.productType == 14">
                    <div class="bnb-content-box">
                        <div class="bnb-inner-box">
                            <div class="bnb-title">
                                {{ item.name }}
                            </div>
                            <div class="bnb-star-box">
                                <div v-for="n in 5" :key="n" class="bnb-star">
                                    <img
                                        src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626745140555.png"
                                        alt=""
                                    />
                                </div>
                                <div class="star-num">5.0</div>
                            </div>
                            <div class="bnb-bottom">
                                <div class="bnb-desc">
                                    {{ item.highligName }}
                                </div>
                                <div class="bnb-price">
                                    <div class="bnb-rmb">￥</div>
                                    {{ item.price }}
                                    <div class="bnb-text">起</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 官方结伴 -->
                <div v-else class="hot-route-content-box">
                    <div class="hot-route-content-title">{{ item.name }}</div>
                    <div v-if="item.highligName" class="hot-route-content-desc">
                        <div class="hot-route-content-desc-left">亮</div>
                        <div class="hot-route-content-desc-right">
                            {{ item.highligName }}
                        </div>
                    </div>
                    <div
                        v-if="item.isHasCoupon"
                        class="hot-route-content-coupon"
                    >
                        {{ item.couponStr }}
                    </div>
                    <div class="hot-route-content-price">
                        <div class="hot-route-content-price-left">
                            <div class="hot-route-content-rmb">￥</div>
                            <div class="hot-route-content-price-num">
                                {{ item.price }}&nbsp;
                            </div>
                            <div class="hot-route-content-text">起</div>
                        </div>
                        <!-- <div class="hot-route-content-price-right">9人已报名</div> -->
                    </div>
                </div>
            </div>
            <!-- for循环结束 -->
        </div>
        <div v-show="showGetMoreBtn" class="more w" @click="getMore">
            <div>查看更多</div>
            <img
                src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626054989259.png"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import { hotRouteList } from "@/http/api";
export default {
    mounted() {
        this.getHotRouteList();
        clearInterval(this.timer);
        this.timer = setInterval(() => {
            this.getRef();
        }, 10000);
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
    data() {
        return {
            cont: "",
            box: "",
            clientW: 1220,
            maxNum: 0,
            hotRouteParams: {
                pageNum: 1,
                pageSize: 12,
                tourModule: "官网热门线路",
            },
            hotRouteList: [],
            timer: null,
        };
    },
    computed: {
        showGetMoreBtn() {
            return this.hotRouteList.length < this.count;
        },
    },
    methods: {
        async getHotRouteList() {
            const { data } = await hotRouteList(this.hotRouteParams);
            if (data.code === 200) {
                this.count = data.count;
                if (data.data.list.length === 0) {
                    return;
                }
                data.data.list.forEach((item) => {
                    if (item.imgUrl.indexOf("http") === -1) {
                        item.imgUrl = this.$oss + item.imgUrl;
                    }
                });
                this.hotRouteList.push(...data.data.list);
                this.$nextTick(() => {
                    this.getRef();
                    setTimeout(() => {
                        this.getRef();
                    }, 100);
                });
            }
        },
        getRef() {
            this.cont = this.$refs.cont;
            this.box = this.$refs.box;
            this.clientW = 1220;
            this.w = this.box[0].offsetWidth;
            this.maxNum = parseInt(this.clientW / this.w);
            // 3. 第一行
            this.firstLine();
            // 4. 其他行
            this.otherLine();
        },
        getMore() {
            this.hotRouteParams.pageNum++;
            this.getHotRouteList();
        },
        firstLine() {
            // 列高的容器
            this.heightArr = [];
            for (var i = 0; i < this.maxNum; i++) {
                // 取第一行所有的高度，作为基础列高
                this.heightArr.push(this.box[i].offsetHeight);
            }
        },
        otherLine() {
            for (var i = this.maxNum; i < this.box.length; i++) {
                // 取最小列高
                let min = Math.min(...this.heightArr);
                // 取最小列高的索引
                let minIndex = this.heightArr.indexOf(min);
                // 设置定位
                this.box[i].style.position = "absolute";
                // 根据最小列高设置top
                this.box[i].style.top = min + "px";
                // 根据最小列高的索引设置left
                this.box[i].style.left = minIndex * this.w + "px";
                // 重点：修改当前列高 = 列高 + 新放置的元素的高
                this.heightArr[minIndex] += this.box[i].offsetHeight;
                this.cont.style.height = Math.max(...this.heightArr) + "px";
            }
        },
        goDownloadPage() {
            this.$router.push("appDownload");
        },
        // 鼠标悬停显示二维码
        showQRcode(event) {
            // event.target.children[0].style.display = "block";
        },
        // 鼠标离开隐藏二维码
        hideQRcode(event) {
            // event.target.children[0].style.display = "none";
        },
    },
};
</script>

<style lang="scss" scoped>
.hot-route-box {
    position: relative;
    margin: 0 auto;
    width: 1220px;
    margin-top: 152px;

    .hot-route-item {
        float: left;
        position: relative;
        padding: 0 10px 20px;
        width: 285px;
        cursor: pointer;

        .qr-code {
            display: none;
            position: absolute;
            top: 0;
            left: 10px;
            background: rgba(0, 0, 0, 0.6);
            z-index: 999;
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            border-radius: 18px;

            img {
                position: absolute;
                top: calc(50% - 93px);
                left: calc(50% - 93px);
                width: 186px;
                height: 186px;
            }
        }

        .hot-route-img {
            width: 285px;
            height: auto;
            .hot-route-img-photo {
                display: block;
                width: 100%;
                height: auto;
                border-radius: 18px 18px 0px 0px;
            }
            .hot-route-img-play {
                position: absolute;
                top: 0;
                left: 10px;
            }
            .hot-route-img-mask {
                position: absolute;
                display: flex;
                justify-content: center;
                top: 0;
                left: 10px;
                width: 86px;
                height: 36px;
                background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626685162706.png)
                    no-repeat;
                span {
                    margin-top: 6px;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #ffffff;
                }
            }
        }
        .hot-route-content-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 14px;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 0px 0px 18px 18px;

            .hot-route-content-title {
                margin-bottom: 12px;
                width: 257px;
                // height: 54px;
                height: auto;
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 28px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .hot-route-content-desc {
                display: flex;
                margin-bottom: 12px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #a3a3a3;

                .hot-route-content-desc-left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 7px;
                    width: 21px;
                    height: 21px;
                    border-radius: 3px;
                    border: 1px solid #f66725;
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #f66725;
                }

                .hot-route-content-desc-right {
                    display: flex;
                    align-items: center;
                    height: 21px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            .hot-route-content-coupon {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 12px;
                width: 136px;
                height: 30px;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #e9747d;
                background: url(https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626054452708.png)
                    no-repeat;
            }

            .hot-route-content-price {
                display: flex;
                justify-content: space-between;
                .hot-route-content-price-left {
                    display: flex;
                    color: #f66725;
                    .hot-route-content-rmb {
                        display: flex;
                        align-items: center;
                        font-size: 20px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #f66725;
                    }
                    .hot-route-content-price-num {
                        font-size: 24px;
                        font-family: Avenir-Heavy, Avenir;
                        font-weight: 800;
                        color: #f66725;
                    }
                    .hot-route-content-text {
                        display: flex;
                        align-items: center;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #f66725;
                    }
                }
                .hot-route-content-price-right {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    font-family: PingFangSC-Light, PingFang SC;
                    font-weight: 300;
                    color: #9b9b9b;
                }
            }
        }

        .bnb-content-box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 118px;
            background: #ffffff;
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 0 0 18px 18px;
            .bnb-inner-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 257px;
                height: 87px;
                .bnb-title {
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    //强制一行显示
                    white-space: nowrap;
                    overflow: hidden;
                    //溢出的文字用省略号表示
                    text-overflow: ellipsis;
                }
                .bnb-star-box {
                    display: flex;
                    align-items: center;
                    margin-top: 6px;
                    .bnb-star {
                        margin-right: 6px;
                    }
                    .star-num {
                        margin-left: 4px;
                        font-size: 20px;
                        font-family: Avenir-Medium, Avenir;
                        font-weight: 500;
                        color: #ffb43b;
                    }
                }
                .bnb-bottom {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    .bnb-desc {
                        width: 148px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #9b9b9b;
                        //强制一行显示
                        white-space: nowrap;
                        overflow: hidden;
                        //溢出的文字用省略号表示
                        text-overflow: ellipsis;
                    }
                    .bnb-price {
                        display: flex;
                        align-items: center;
                        font-size: 24px;
                        font-family: Avenir-Heavy, Avenir;
                        font-weight: 800;
                        color: #f66725;
                        .bnb-rmb {
                            font-size: 20px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #f66725;
                        }
                        .bnb-text {
                            margin-left: 2px;
                            font-size: 20px;
                            font-family: PingFangSC-Semibold, PingFang SC;
                            font-weight: 600;
                            color: #f66725;
                        }
                    }
                }
            }
        }
    }
}
.more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    font-size: 28px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #26a1ff;
    cursor: pointer;
    img {
        margin-left: 6px;
        width: 18px;
        height: 18px;
    }
}
</style>
