<template>
    <div>
        <div class="hot-route-box">
            <!-- for循环开始 -->
            <div
                v-for="(item, index) in hotRouteList"
                @click="goDownloadPage"
                class="hot-route-item"
                ref="box"
                :key="index"
            >
                <div class="hot-route-left">
                    <img :src="item.imgUrl" />
                </div>
                <div class="hot-route-right">
                    <div class="hot-route-name">{{ item.name }}</div>
                    <div v-if="item.highligName" class="hot-route-content-desc">
                        <div class="hot-route-content-desc-left">亮</div>
                        <div class="hot-route-content-desc-right">
                            {{ item.highligName }}
                        </div>
                    </div>
                    <div class="hot-route-price">￥ {{ item.price }} 起</div>
                </div>
            </div>
            <!-- for循环结束 -->
        </div>
        <div v-show="showGetMoreBtn" class="more" @click="getMore">
            <div>查看更多</div>
            <img
                src="https://oss.cuttlefish.vip/file/backstageManagement/serveManage/1626054989259.png"
                alt=""
            />
        </div>
    </div>
</template>

<script>
import { hotRouteList } from "@/http/api";
export default {
    mounted() {
        this.getHotRouteList();
    },
    beforeDestroy() {
    },
    data() {
        return {
            cont: "",
            box: "",
            clientW: 1220,
            maxNum: 0,
            hotRouteParams: {
                pageNum: 1,
                pageSize: 12,
                tourModule: "官网热门线路",
            },
            hotRouteList: [],
        };
    },
    computed: {
        showGetMoreBtn() {
            return this.hotRouteList.length < this.count;
        },
    },
    methods: {
        async getHotRouteList() {
            const { data } = await hotRouteList(this.hotRouteParams);
            if (data.code === 200) {
                this.count = data.count;
                if (data.data.list.length === 0) {
                    return;
                }
                data.data.list.forEach((item) => {
                    if (item.imgUrl.indexOf("http") === -1) {
                        item.imgUrl = this.$oss + item.imgUrl;
                    }
                });
                this.hotRouteList.push(...data.data.list);
            }
        },
        getMore() {
            this.hotRouteParams.pageNum++;
            this.getHotRouteList();
        },

        goDownloadPage() {
            this.$router.push("appDownload");
        },
    },
};
</script>

<style lang="scss" scoped>
.hot-route-box {
    padding: 10px;
    background-color: #fff;
    box-sizing: border-box;
    .hot-route-item {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .hot-route-left {
            img {
                width: 100px;
                height: 100px;
                border-radius: 10px;
            }
        }
        .hot-route-right {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-size: 16px;

            .hot-route-name {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .hot-route-content-desc {
                display: flex;
                margin-bottom: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #a3a3a3;

                .hot-route-content-desc-left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 7px;
                    width: 16px;
                    height: 16px;
                    border-radius: 3px;
                    border: 1px solid #f66725;
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #f66725;
                }

                .hot-route-content-desc-right {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            .hot-route-price {
                color: #f66725;
                font-family: Avenir-Heavy, Avenir;
            }
        }
    }
}
.more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 18px;
    font-size: 16px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #26a1ff;
    cursor: pointer;
    img {
        margin-left: 6px;
        width: 18px;
        height: 18px;
    }
}
</style>
